//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  data () {
    return {
      visible: false,
      confirmLoading: false,
      dataRecord: [],
      srcUrl: ''
    }
  },
  methods: {
    // 初始化方法
    design (record) {
      this.dataRecord = record
      this.visible = true
      this.srcUrl = process.env.VUE_APP_API_BASE_URL + '/designer/index.html?token=' + Vue.ls.get(ACCESS_TOKEN) + '&modelId=' + record.modelId
    },
    /**
     * 關閉
     */
    handleClose () {
      this.visible = false
      this.srcUrl = ''
    }
  }
}
