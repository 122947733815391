//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis } from '@/components'
import { modelSetAsNew, modelVersion } from '@/api/modular/flowable/modelManage'

export default {
  components: {
    Ellipsis
  },

  data () {
    return {
      loading: false,
      tableLoading: false,
      columns: [],
      loadData: [],
      recordData: {}
    }
  },
  methods: {
    /**
     * 打開此頁面
     */
    openVersion (record) {
      this.recordData = record
      this.getModelList()
      this.addAction()
    },
    /**
     * 初始化table信息
     */
    addAction () {
      this.columns.push(
        {
          title: '模型名稱',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '唯一編碼',
          dataIndex: 'key'
        },
        {
          title: '版本',
          dataIndex: 'version',
          scopedSlots: { customRender: 'version' }
        },
        {
          title: '創建時間',
          dataIndex: 'created',
          scopedSlots: { customRender: 'created' }
        },
        {
          title: '創建人',
          dataIndex: 'createdBy'
        },
        {
          title: '描述',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' }
        }
      )
      if (this.hasPerm('flowableModel:setAsNew')) {
        this.columns.push({
          title: '操作',
          width: '100px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        })
      }
    },
    /**
     * 獲取列表數據
     */
    getModelList () {
      this.tableLoading = true
      modelVersion({ id: this.recordData.id }).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.loadData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 設為新版
     */
    handleSetAsNew (record) {
      this.loading = true
      modelSetAsNew({ id: record.id, modelId: this.recordData.id }).then((res) => {
        this.loading = false
        if (res.success) {
          this.$message.success('設為新版成功')
          this.getModelList()
        } else {
          this.$message.error('設為新版失敗：' + res.message)
        }
      })
    },
    returnPage () {
      this.columns = []
      this.loadData = []
      this.recordData = {}
      this.$emit('ok')
    }
  }

}
