//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flowableCategoryDeploy } from '@/api/modular/flowable/defenitionManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      record: {},
      categoryList: [],
      recordData: {},
      form: this.$form.createForm(this)
    }
  },
  created () {
    /**
     * 獲取到分類列表
     */
    flowableCategoryList().then((res) => {
      this.categoryList = res.data
    })
  },
  methods: {
    // 初始化方法
    deploy (record) {
      this.recordData = record
      this.visible = true
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          values.modelId = this.recordData.modelId
          flowableCategoryDeploy(values).then((res) => {
            if (res.success) {
              this.$message.success('部署成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('部署失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          // eslint-disable-next-line handle-callback-err
          }).catch((err) => {
            // 這裏咱先啥也不幹
          })
        }
      })
    },
    handleCancel () {
      this.recordData = {}
      this.form.resetFields()
      this.visible = false
    }
  }
}
