//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis, XCard } from '@/components'
import { modelList, modelDelete, modelImportModel } from '@/api/modular/flowable/modelManage'
import addForm from './addForm'
import editForm from './editForm'
import preview from './preview'
import design from './design'
import deployForm from './deployForm'
import version from './version'
export default {
  components: {
    XCard,
    Ellipsis,
    addForm,
    editForm,
    preview,
    design,
    deployForm,
    version
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      tableLoading: false,
      Loading: false,
      columns: [],
      loadData: [],
      // 查詢參數
      queryParam: {},
      // 界面切換
      indexShow: true,
      versionShow: false
    }
  },
  created () {
    this.getModelList()
    this.addAction()
  },
  methods: {
    /**
     * 初始化table信息
     */
    addAction () {
      this.columns.push(
        {
          title: '模型名稱',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '唯一編碼',
          dataIndex: 'key'
        },
        {
          title: '版本',
          dataIndex: 'version',
          scopedSlots: { customRender: 'version' }
        },
        {
          title: '創建時間',
          dataIndex: 'created',
          scopedSlots: { customRender: 'created' }
        },
        {
          title: '創建人',
          dataIndex: 'createdBy'
        },
        {
          title: '描述',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' }
        }
      )
      if (this.hasPerm('flowableModel:preview') || this.hasPerm('flowableModel:edit') || this.hasPerm('flowableModel:delete')) {
        this.columns.push({
          title: '操作',
          width: '200px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        })
      }
    },
    /**
     * 獲取列表數據
     */
    getModelList () {
      this.tableLoading = true
      modelList(this.queryParam).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.loadData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 關閉整個頁面
     */
    handleCancel () {
      this.loadData = []
      this.columns = []
    },
    /**
     * 打開version列表
     */
    openVersion (record) {
      this.indexShow = false
      this.versionShow = true
      this.$refs.version.openVersion(record)
    },
    /**
     * 關閉version界面
     */
    closeVersion () {
      this.indexShow = true
      this.versionShow = false
      this.getModelList()
    },
    /**
     * 刪除
     */
    handleDelete (record) {
      this.Loading = true
      modelDelete({ id: record.id }).then((res) => {
        this.Loading = false
        if (res.success) {
          this.$message.success('刪除成功')
          this.getModelList()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      })
    },
    /**
     * 導入模型
     */
    customRequest (data) {
      const formData = new FormData()
      formData.append('file', data.file)
      this.tableLoading = true
      modelImportModel(formData).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.$message.success('導入成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('導入失敗：' + res.message)
        }
      })
    },
    /**
     * 部署
     */
    handleDeploy (record) {
      this.$refs.deployForm.deploy(record)
    },
    /**
     * 新增
     */
    handleAdd () {
      this.$refs.addForm.add()
    },
    /**
     * 設計
     */
    handleDesign (record) {
      this.$refs.design.design(record)
    },
    /**
     * 編輯
     */
    handleEdit (record) {
      this.$refs.editForm.edit(record)
    },
    /**
     * 預覽
     */
    handlePreview (record) {
      this.$refs.preview.preview(record)
    },
    /**
     * 子組件成功後返回
     */
    refOk () {
      this.getModelList()
    }
  }
}
