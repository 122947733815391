//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { modelPreview } from '@/api/modular/flowable/modelManage'
export default {
  data () {
    return {
      visible: false,
      confirmLoading: false,
      previewData: ''
    }
  },
  methods: {
    // 初始化方法
    preview (record) {
      this.visible = true
      this.confirmLoading = true
      modelPreview({ id: record.id }).then((res) => {
        this.confirmLoading = false
        if (res.success) {
          this.previewData = res.data
        } else {
          this.$message.error('預覽失敗：' + res.message)
          this.handleCancel()
        }
      })
    },
    handleCancel () {
      this.previewData = ''
      this.visible = false
    }
  }
}
